import React, { useEffect, useMemo, useState } from 'react';
import YouTube from 'react-youtube';
import { useQueryParam } from '../../hooks/useQueryParams';
import { PlayerWrapper } from '../PlayerWrapper';

export interface IYoutubeProps {
}

const options = {
    height: '390',
    width: '640',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        controls: 1,
        listType: 'playlist',
        list: 'PL86SiVwkw_oc8r_X6PL6VqcQ7FTX4923M'
    },
};

let playingNext = true;

export function YoutubeWithWrapper(props: IYoutubeProps) {
    const playlist = useQueryParam('playlist');
    const [playing, setPlaying] = useState(false);
    const [video, setVideo] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState("#3e3e3e")
    const [fullscreen, setFullscreen] = useState(false)

    const opts = useMemo(() => {
        return {
            ...options,
            playerVars: {
                ...options.playerVars,
                list: playlist || "PL86SiVwkw_oc8r_X6PL6VqcQ7FTX4923M"
            }
        };
    }, [playlist]);

    useEffect(() => {
        function onKeyDown (e) {
            if (e.key === 'e') {
                setFullscreen(f => !f)
            }
        }

        document.addEventListener('keydown', onKeyDown)

        return () => {
            document.removeEventListener('keydown', onKeyDown)
        }
    }, [])

    function onReady(event) {
        setVideo(event.target);

        event.target.setShuffle(true);
        event.target.playVideoAt(0);

        setTimeout(() => {
            event.target.playVideo();
        }, 1000);
    }

    async function onStateChange(e) {
        if (e.data === 1 && playingNext) {
            const videoLength = video.getDuration()

            if (videoLength < 65) {
                console.log('video too short', videoLength)
                next();
                return;
            }

            const duration = Math.random() * (videoLength - 65)

            video.seekTo(duration, true)

            playingNext = false;
        }
    }

    function onError() {
        next();
    }

    // Player options
    function play() {
        setPlaying(true);
    }

    function pause() {
        setPlaying(false);
    }

    function next() {
        if (video) {
            playingNext = true
            video.nextVideo();
        }

        return Promise.resolve()
    }

    function prev() {
        if (video) {
            video.prevVideo();
        }
    }
    // Player options

    return (
        <PlayerWrapper
            className={fullscreen ? 'big' : undefined}
            onNext={next}
            playing={playing}
            setPlaying={setPlaying}
            backgroundColor={backgroundColor}
            showTime
        >
            <YouTube
                className={fullscreen ? 'big' : undefined}
                opts={opts as any}
                onReady={onReady}
                onPause={pause}
                onPlay={play}
                onError={onError}
                onStateChange={onStateChange}
            />
        </PlayerWrapper>
    );
}
