import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Spotify from 'spotify-web-api-js'
import useCookie from './useCookie';

const url = process.env.SPOTIFY_GET_TOKEN

function getTokenWithRefreshToken(refresh_token) {
    return axios.post(`${url}/refresh_token`, {
        data: {
            refresh_token
        }
    }).then(res => res.data)
}

function getTokenWithCode(code, redirect_uri) {
    return axios.post(`${url}/token`, {
        data: {
            code,
            redirect_uri
        }
    }).then(res => res.data)
}

export default function useSpotifyAuthentication() {
    const [token, setToken] = useCookie('access_token', null)
    const [refreshToken, setRefreshToken] = useCookie('refresh_token', null)

    useEffect(() => {
        if (token) return

        if (refreshToken) {
            getTokenWithRefreshToken(refreshToken).then(({
                access_token,
                expires_in
            }) => {
                setToken(access_token, expires_in)
            })

            return
        }

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code')
        if (code) {
            getTokenWithCode(code, window.location.origin + window.location.pathname).then(({
                access_token,
                expires_in,
                refresh_token
            }) => {
                setToken(access_token, expires_in)
                setRefreshToken(refresh_token)

                window.history.replaceState({}, document.title, "");
            })
        }
    }, [token, refreshToken]);

    return token
}

export function useSpotify() {
    const token = useSpotifyAuthentication()

    const spotifyWebApi = useMemo(() => {
        if (!token) return null

        const api = new Spotify()
        api.setAccessToken(token)

        return api
    }, [token])

    return { authorized: !!token, spotifyWebApi }
}