
import React, { useState, useEffect, createContext, useContext } from "react";
import axios from "axios";
import useCookie from "./useCookie";

const AuthContext = createContext(null)

export const AuthProvider = ({ children, url }) => {
  const [loading, setLoading] = useState(true);

  const [accessToken, setAccessToken, deleteAccessToken] = useCookie('access_token', null)
  const [refreshToken, setRefreshToken, deleteRefreshToken] = useCookie('refresh_token', null)

  function logout() {
    deleteAccessToken()
    deleteRefreshToken()
  }

  function clearAccessToken() {
    deleteAccessToken()
  }

  function fetchAccessTokenWithRefreshToken(refresh_token) {
    setLoading(true)

    return axios.post(`${url}/refresh_token`, {
      data: {
        refresh_token
      }
    })
      .then(res => res.data)
      .finally(() => {
        setLoading(false)
      })
  }

  function fetchAccessTokenWithCode(code, redirect_uri) {
    setLoading(true)

    return axios.post(`${url}/token`, {
      data: {
        code,
        redirect_uri
      }
    })
      .then(res => res.data)
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (accessToken) {
      setLoading(false)

      return
    }

    if (refreshToken) {
      fetchAccessTokenWithRefreshToken(refreshToken)
        .then(res => {
          const { access_token, expires_in } = res || {}

          setAccessToken(access_token, expires_in)
        })

      return
    }

    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has('code')) {
      const code = urlParams.get('code')

      fetchAccessTokenWithCode(code, window.location.origin + window.location.pathname)
        .then(res => {
          const {
            access_token,
            expires_in,
            refresh_token,
          } = res || {}

          setAccessToken(access_token, expires_in)
          setRefreshToken(refresh_token)
        })
        .finally(() => {
          window.history.replaceState({}, document.title, `${window.location.pathname}`);
        })

      return
    }

    setLoading(false)
  }, [accessToken])

  return (
    <AuthContext.Provider value={{
      accessToken,
      clearAccessToken,
      logout,
      loading,
    }}>
      {children}
    </AuthContext.Provider>
  )
}
export default function useOAuth(): any {
  const context = useContext(AuthContext)

  return context
}
