import { useEffect, useState } from 'react'
import axios from 'axios';
import { useSpotify } from '../SpotifyStandalone/useSpotifyAuthentication'
import useScript from '../../hooks/useScript'
import { getItem, setItem } from '../SpotifyStandalone/useCookie'

window.onSpotifyWebPlaybackSDKReady = () => { console.log('onSpotifyWebPlaybackSDKReady') }

function loadScript(): Promise<void> {
  return new Promise(resolve => {
    if (window.Spotify) {
      resolve();
    } else {
      window.onSpotifyWebPlaybackSDKReady = resolve;
    }
  });
}

const url = process.env.SPOTIFY_GET_TOKEN

export default function useSpotifyWebPlayback(token) {
  const { spotifyWebApi } = useSpotify(token)

  const [player, setPlayer] = useState(null)
  const [currentState, setCurrentState] = useState(null)

  // const [accessToken, setAccessToken, deleteAccessToken] = useCookie('access_token', null)
  // const [refreshToken, setRefreshToken, deleteRefreshToken] = useCookie('refresh_token', null)

  function fetchAccessTokenWithRefreshToken(refresh_token) {
    return axios.post(`${url}/refresh_token`, {
      data: {
        refresh_token
      }
    })
      .then(res => res.data)
  }

  useEffect(() => {
    loadScript().then(() => {
      const spotifyPlayer = new Spotify.Player({
        name: 'Power Hour',
        getOAuthToken: cb => {
          // const accessToken = getItem('access_token')

          // if (accessToken) {
          //   cb(accessToken)

          //   return
          // }

          const refreshToken = getItem('refresh_token')

          fetchAccessTokenWithRefreshToken(refreshToken).then(({ access_token, expires_in }) => {
            cb(access_token)

            setItem('access_token', access_token, expires_in)
          })
        }
      })

      spotifyPlayer.addListener('player_state_changed', async (data) => {
        const track = data?.track_window?.current_track

        if (!track) return

        setCurrentState({
          item: {
            artists: track.artists?.map(artist => artist.name).join(', '),
            image: track.album.images?.[0] || '',
            images: {
              small: track.album.images?.[1] || '',
              medium: track.album.images?.[0] || '',
              big: track.album.images?.[2] || ''
            },
            duration: data?.duration,
            name: track.name,
          },
          playing: !data?.paused,
          volume: data.volume
        })
      })

      spotifyPlayer.addListener('ready', ({ device_id }) => {
        console.log('Ready with Device ID', device_id);

        // spotifyPlayer.transfer
        spotifyWebApi.transferMyPlayback([device_id], { play: false })

        spotifyPlayer.resume()
      });

      // Not Ready
      spotifyPlayer.addListener('not_ready', ({ device_id }) => {
        console.log('Device ID has gone offline', device_id);
      });

      spotifyPlayer.addListener('initialization_error', ({ message }) => {
        console.error(message);
      });

      spotifyPlayer.addListener('authentication_error', ({ message }) => {
        console.error(message);
      });

      spotifyPlayer.addListener('account_error', ({ message }) => {
        console.error(message);
      });

      spotifyPlayer.connect().then(success => {
        if (success) {
          console.log('The Web Playback SDK successfully connected to Spotify!');

        } else {
          console.log('The Web Playback SDK failed connected to Spotify!');
        }
      })

      setPlayer(spotifyPlayer)
    })
  }, [])

  useScript('https://sdk.scdn.co/spotify-player.js')

  return { player, currentState }
}