import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Container, Icon } from 'semantic-ui-react';
import { Fireworks } from '../Fireworks';
import { useSpotify } from '../SpotifyStandalone/useSpotifyAuthentication';
import useDebounce from '../../hooks/useDebounce';
import Clock from '../Clock/Clock';
import { useLocalStorage } from 'beautiful-react-hooks';
import styles from './player.module'

export interface ISpotifyProps {
  spotifyWebApi
  currentState
}

var audio = new Audio('can-sound.mp3');
audio.volume = .5;

function Playing({ onClick }) {
  return <Icon name='pause circle' size="huge" className="icon-button" onClick={onClick} />
}

function Paused({ onClick }) {
  return <Icon name='play circle' size="huge" className="icon-button" onClick={onClick} />
}

function Player({ pause, play, playing, onVolumeChange, volume }) {
  return (
    <div className="position-absolute absolute-center show-on-mouse">
      <div className="control-button">
        {(playing ?
          <Playing onClick={pause} /> :
          <Paused onClick={play} />)}
      </div>
      <div className={styles.volumeInput}>
        <input title="volume" id="volume" type="range" min="0" max="100" onChange={onVolumeChange} value={volume} />
      </div>
    </div>
  )
}

function useDebouncedValue(onChange, defaultValue) {
  const [value, setValue] = useState(defaultValue)
  const debouncedValue = useDebounce(value, 300)

  useEffect(() => {
    if (debouncedValue !== null) {
      onChange && onChange(debouncedValue)
    }
  }, [debouncedValue])

  return [value, setValue]
}

export function SpotifyWithPlayback({ spotifyWebApi, currentState }: ISpotifyProps) {
  const [volume, setVolume] = useLocalStorage<number>('spotify-volume', 1)

  const currentSongName = useRef('')
  const currentImageUrl = useRef('')

  const shouldHaveChanged = useRef(false)

  const playing = currentState.playing
  const currentSong = currentState.item

  const [volumeValue, setVolumeValue] = useDebouncedValue((volume) => {
    if (volume !== null) {
      setVolume(volume)
    }
  }, volume)

  function onVolumeChange(e) {
    setVolumeValue(e.target.value)
  }

  const gameRef = useRef<any>();

  useEffect(() => {
    spotifyWebApi.setVolume(Number(volume) / 100)
  }, [volume])

  async function seekToRandom() {
    const { item } = currentState

    const roundLength = 65000;

    if (item.duration > roundLength) {
      const startTime = lerp(0, item.duration - roundLength, Math.random())// Math.floor(Math.random() * (item.duration - roundLength));

      await spotifyWebApi.seek(startTime)
    }
  }

  async function setBackgroundColor() {
    const { item } = currentState

    const color = await getAverageColor(item.image.url);
    gameRef.current.transition(color);
  }

  // useEffect(() => {
  //   if (currentSongName.current && currentSongName.current !== currentState.item.name) {
  //     console.log(currentSongName.current, currentState.item.name)
  //     seekToRandom()
  //   }

  //   if (currentImageUrl.current !== currentState.item.image.url) {
  //     setBackgroundColor()
  //   }

  //   currentImageUrl.current = currentState.item.image.url
  //   currentSongName.current = currentState.item.name

  // }, [currentState])

  // useEffect(() => {
  //   if (currentSongName.current && currentSongName.current !== currentState.item.name) {
  //     console.log(currentSongName.current, currentState.item.name)
  //     seekToRandom()
  //   }

  //   if (currentImageUrl.current !== currentState.item.image.url) {
  //     setBackgroundColor()
  //   }

  //   currentImageUrl.current = currentState.item.image.url
  //   currentSongName.current = currentState.item.name

  // }, [currentState])

  useEffect(() => {
    if (currentSongName.current === currentState.item.name) {
      if (shouldHaveChanged.current) {
        console.log('should have nexted but did not :(')
        shouldHaveChanged.current = false

        spotifyWebApi?.skipToNext()
      }
    }

    if (currentSongName.current && currentSongName.current !== currentState.item.name) {
      seekToRandom()
      shouldHaveChanged.current = false
    }

    if (currentImageUrl.current !== currentState.item.image.url) {
      setBackgroundColor()
    }

    currentSongName.current = currentState.item.name
    currentImageUrl.current = currentState.item.image.url
  }, [currentState, spotifyWebApi])

  function doFireworks(amount) {
    if (gameRef.current) {
      for (var i = 0; i < amount; i++) {
        const x = window.innerWidth / (amount + 1) * (i + 1);
        const y = randomRange(40, 100)
        setTimeout(() => {
          gameRef.current.emit(x, y, 25);

        }, Math.floor(Math.random() * 1000));
      }
    }
  }

  async function handleTimerFinish(minute) {
    audio.volume = ((volumeValue || 100) / 100);
    audio.play();

    doFireworks(minute)

    await spotifyWebApi?.skipToNext()
    shouldHaveChanged.current = true
  }

  async function play() {
    try {
      await spotifyWebApi?.play()
    } catch (err) {
      console.error(err)
    }
  }

  async function pause() {
    try {
      await spotifyWebApi?.pause()
    } catch (err) {
      console.error(err)
    }
  }

  async function getAverageColor(url): Promise<string> {
    return new Promise(resolve => {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = url;

      image.addEventListener('load', () => {
        var rgb = getAverageRGB(image);
        resolve('rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')');
      });
    });
  }

  return (
    <>
      <Fireworks ref={gameRef} />
      <Container className="p-2 center d-flex flex-column absolute-center player-container">
        <div className={`${styles.player} p-2`}>
          {currentSong && (
            <>
              <Clock running={playing} onTimerFinish={handleTimerFinish} />

              <div className={styles.display}>
                <div className={styles.songName}>{currentSong.name}</div>
                <div className={styles.songArtists}>
                  {currentSong.artists}
                </div>
              </div>

              <div className={`${styles.albumControls} position-relative`}>
                <img alt="Album cover" src={currentSong?.images?.big.url} />
                <Player playing={playing} play={play} pause={pause} volume={volumeValue} onVolumeChange={onVolumeChange} />
              </div>
            </>
          )}
        </div>
      </Container>
    </>
  );
}

export function lerp(a, b, t) {
  return a + (b - a) * t;
}

function randomRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function getAverageRGB(imgEl) {

  var blockSize = 5, // only visit every 5 pixels
    defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
    canvas = document.createElement('canvas'),
    context = canvas.getContext && canvas.getContext('2d'),
    data, width, height,
    i = -4,
    length,
    rgb = { r: 0, g: 0, b: 0 },
    count = 0;

  if (!context) {
    return defaultRGB;
  }

  height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

  context.drawImage(imgEl, 0, 0);

  try {
    data = context.getImageData(0, 0, width, height);
  } catch (e) {
    console.log('diff domain');
    /* security error, img on diff domain */
    return defaultRGB;
  }

  length = data.data.length;

  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i + 1];
    rgb.b += data.data[i + 2];
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count);
  rgb.g = ~~(rgb.g / count);
  rgb.b = ~~(rgb.b / count);

  return rgb;
}
