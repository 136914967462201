import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SpotifyWithWrapper } from '../Spotify/SpotifyWithWrapper';
import { SpotifyStandalone } from '../SpotifyStandalone';
import OuterPlayer from '../SpotifyWebPlayback/OuterPlayer';
// import { SpotifyWithPlayback } from '../SpotifyWebPlayback/Player';
import { YoutubeWithWrapper } from '../Youtube/YoutubeWithWrapper';

// const SpotifyStandaloneLazy = React.lazy(() => import('../SpotifyStandalone'));
// const SpotifyWithWrapperLazy = React.lazy(() => import('../Spotify/SpotifyWithWrapper'));
// const YoutubeWithWrapperLazy = React.lazy(() => import('../Youtube/YoutubeWithWrapper'));

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<OuterPlayer />} />
                <Route path="/legacy" element={<SpotifyStandalone />} />
                <Route path="/youtube" element={<YoutubeWithWrapper />} />
                <Route path="/ptr" element={<SpotifyWithWrapper />} />
                <Route path="/no-server" element={<SpotifyStandalone />} />
            </Routes>
        </BrowserRouter>
    )
}