import { useLocalStorage } from 'beautiful-react-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'semantic-ui-react';
import { Fireworks } from '../Fireworks';
import Timer from '../Timer';

export interface IPlayerWrapperProps {
    onNext: () => void;
    setPlaying: (value: boolean) => void;
    playing: boolean;
    children;
    backgroundColor: string;
    showTime: boolean;
    className?: string;
}

var audio = new Audio('/can-sound.mp3');
audio.volume = .5;

const countdownTime = 60;

export function PlayerWrapper({
    backgroundColor,
    className = 'p-2',
    children,
    playing,
    setPlaying,
    showTime,
    onNext,
}: IPlayerWrapperProps) {
    const [currentMinute, setCurrentMinute] = useLocalStorage<number>("currentMinute", 1);
    const [displayTime, setDisplayTime] = useState(true);

    const gameRef = useRef<any>();

    useEffect(() => {
        if (gameRef && gameRef.current) {
            gameRef.current.transition(backgroundColor)
        }
    }, [backgroundColor])

    async function next() {
        audio.play();

        // Emit confetti
        if (gameRef.current) {
            for (var i = 0; i < currentMinute; i++) {
                const x = window.innerWidth / (currentMinute + 1) * (i + 1);
                const y = randomRange(40, 100)

                setTimeout(() => {
                    gameRef.current.emit(x, y, 25);

                }, Math.floor(Math.random() * 1000));
            }
        }

        if (currentMinute === 60) {
            setCurrentMinute(1);
        } else {
            setCurrentMinute(currentMinute + 1);
        }

        setPlaying(true);
        onNext && await onNext();
    }

    function resetMinutes() {
        setCurrentMinute(1);
    }

    function toggleTimer() {
        setDisplayTime(!displayTime);
    }

    return (
        <>
            <Fireworks ref={gameRef} />
            <Container className={`${className} center d-flex flex-column absolute-center player-container`}>
                <div className={`${className}`} style={{ borderRadius: "1rem", background: "rgba(0,0,0,52%)", boxShadow: "1px 1px 10px #000000a6", position: 'relative' }}>
                    {showTime && (
                        <div className="counters">
                            <div className="position-absolute left time" onClick={resetMinutes}>{('0' + currentMinute).substr(-2, 2)}</div>
                            <div className="position-absolute right" onClick={toggleTimer}>
                                <Timer
                                    renderType="seconds"
                                    counting={playing}
                                    onFinished={next}
                                />
                            </div>
                        </div>
                    )}
                    {children}
                </div>
            </Container>
        </>
    );
}

function randomRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}