import { useLocalStorage } from 'beautiful-react-hooks';
import React from 'react'
import Timer from '../Timer'

export default function Clock({ running, onTimerFinish }) {
  const [currentMinute, setCurrentMinute] = useLocalStorage<number>("currentMinute", 1);

  function handleTimerFinish() {
    const nextMinute = (currentMinute % 60) + 1
    setCurrentMinute(nextMinute);

    return onTimerFinish(nextMinute)
  }


  function resetMinutes() {
    setCurrentMinute(1);
  }

  return (
    <div className="counters">
      <div className="position-absolute left time" onDoubleClick={resetMinutes}>{('0' + currentMinute).substr(-2, 2)}</div>
      <div className="position-absolute right">
        <Timer renderType="seconds" counting={running} onFinished={handleTimerFinish} />
      </div>
    </div>
  )
}