import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { randomRange } from '../../utils';
import { Camera } from './Camera';
import { Sync } from '../SpotifyStandalone/useAnalysis'
import { interpolateRgb, interpolateBasis } from 'd3-interpolate'

const sin = (ctx, xOffset, yOffset, amplitude, frequency, tick = 100) => {
    const y = x => (amplitude * Math.sin((x / frequency) + xOffset) + yOffset)
    const { width } = ctx.canvas
    ctx.beginPath()
    for (var x = -50; x < width + 50; x += tick) {
        if (x === -50) {
            ctx.moveTo(x, y(x))
        } else {
            ctx.lineTo(x, y(x))
        }
    }
}

export const Fireworks = forwardRef((props, ref: any) => {
    const canvas = useRef<HTMLCanvasElement>();

    const [game, setGame] = useState(null);

    useImperativeHandle(ref, () => ({
        emit(x, y, count) {
            if (game) {
                game.emit(x, y, count);
            }
        },

        transition(color) {
            if (game) {
                game.transition(color);
            }
        },

        setAnalysis(analysis) {
            if (game) {
                game.setAnalysis(analysis);
            }
        },

        setSyncInitialProgress(syncInitialProgress) {
            if (game) {
                game.setSyncInitialProgress(syncInitialProgress);
            }
        }
    }));

    useEffect(() => {
        if (canvas.current) {
            canvas.current.width = window.innerWidth;
            canvas.current.height = window.innerHeight;
            const context = canvas.current.getContext('2d');
            const game = new Game(context, canvas.current.width, canvas.current.height);

            setGame(game);

            const onResize = () => {
                canvas.current.width = window.innerWidth;
                canvas.current.height = window.innerHeight;

                game.width = canvas.current.width;
                game.height = canvas.current.height;
            }

            window.addEventListener('resize', onResize)

            return () => {
                window.removeEventListener('resize', onResize);
            }
        }
    }, [canvas]);

    useEffect(() => {
        if (game) {
            game.start();
        }
    }, [game]);

    function handleClick(e) {
        const x = e.clientX;//randomRange(200, window.innerWidth - 200);
        const y = e.clientY;//randomRange(200, window.innerHeight - 200);

        if (game) {
            const { r, g, b } = randomColor();
            //game.transition(`rgb(${r}, ${g}, ${b})`);

            //game.firework(x, y);

            // let currentMinute = 60;
            // for (var i = 0; i < currentMinute; i++) {
            //     const x = window.innerWidth / (currentMinute + 1) * (i + 1);//randomRange(0, window.innerWidth - 0);
            //     //const y = randomRange(0, window.innerHeight - 0);
            //     const y = randomRange(40, 100)
            //     setTimeout(() => {
            //        game.emit(x, y, 25);
            //     }, Math.floor(Math.random() * 1000));
            //  }
        }
    }

    return (
        <canvas id="canvas" ref={canvas} onClick={handleClick}></canvas>
    )
})

// function randomColor() {
//     //return '#'+(Math.random()*0xFFFFFF<<0).toString(16);

//     return {
//         r: Math.floor(Math.random() * 255),
//         g: Math.floor(Math.random() * 255),
//         b: Math.floor(Math.random() * 255)
//     }
// }



function randomColor() {
    const colors = [
        { r: 255, g: 154, b: 162 },
        { r: 255, g: 183, b: 178 },
        { r: 255, g: 218, b: 193 },
        { r: 226, g: 240, b: 203 },
        { r: 181, g: 234, b: 215 },
        { r: 199, g: 206, b: 243 },
    ];

    return colors[Math.floor(Math.random() * colors.length)];
}

const camera = new Camera();

class Game {
    g;
    sync: Sync = new Sync()

    entities = [] as Entity[];
    particlePool = [] as Particle[];

    transitionEntities = [];

    // stars = [];

    // lastInterval = null
    // // colors = ['#18FF2A', '#7718FF', '#06C5FE', '#FF4242', '#18FF2A']

    // // colors = ['rgba(255, 0, 255, .07)', 'rgba(0, 255, 255, .04)', 'rgba(255, 255, 0, .06)', 'rgba(255, 255, 255, .05)']
    // colors = [(a) => `rgba(255, 255, 255, ${a})`, (a) => `rgba(0, 255, 255, ${a})`, (a) => `rgba(255, 255, 0, ${a})`, (a) => `rgba(255, 255, 255, ${a})`]

    // currentColor

    // lastIntervalBars = null
    // currentColorBars

    // lastIntervals = {}
    // currentColors = {}

    // changingStarIndex = 0

    constructor(g, public width: number, public height: number) {
        this.g = g;

        this.tick = this.tick.bind(this);

        // for (var i = 0; i < 25; i++) {
        //     let index = i % 12
        //     const size = (12 - index) * 25 // (Math.random() * (300) - 200) + 200 // (12 - index) * 25

        //     this.stars[i] = {
        //         id: i,
        //         x: Math.random() * window.innerWidth,
        //         y: Math.random() * window.innerHeight,
        //         size,
        //         color: 'rgba(255, 255, 255, .03)',
        //         angle: Math.random() * (Math.PI * 2),
        //         speed: Math.random() * (0.5) + 0.05
        //     }
        // }
    }

    setSyncInitialProgress(progress) {
        this.sync.setInitialProgress(progress)
    }

    setAnalysis(analysis) {
        this.sync.setAnalysis(analysis)
    }

    firework(x, y) {
        for (var i = 0; i < 40; i++) {
            this.entities.push(
                new Firework(
                    x,
                    y,
                    Math.random() * (Math.PI * 2),
                    5,
                    randomRange(100, 150),
                    randomColor()
                ));
        }
    }

    emit(x, y, count, type: { new(x, y, angle, speed, lifeTime, color): Particle } = Particle) {
        for (var i = 0; i < count; i++) {
            if (this.particlePool.length > 0) {
                const particle = this.particlePool.pop();
                particle.x = x;
                particle.y = y;
                particle.angle = Math.random() * (Math.PI * 2);
                particle.speed = Math.random() * 5;
                particle.life = 0;
                particle.lifeTime = randomRange(100, 150);
                particle.color = randomColor();
                particle.removed = false;
                particle.ya = -randomRange(5, 15);
                this.entities.push(particle);
            } else {
                if (this.entities.length < 1000) {
                    this.entities.push(
                        new type(
                            x,
                            y,
                            Math.random() * (Math.PI * 2),
                            Math.random() * 5, randomRange(100, 150),
                            randomColor()
                        ));
                }
            }
        }
    }

    transition = (color) => {
        if (this.transitionEntities.length > 1) {
            const te = this.transitionEntities.shift();
            te.height = 0;
            te.color = color;
            te.life = 0;
            te.removed = false;

            this.transitionEntities.push(te);
        } else {
            this.transitionEntities.push(new Transition(0, 0, color));
        }
    }

    start() {
        requestAnimationFrame(this.tick);
    }

    tick(now) {
        requestAnimationFrame(this.tick);

        // this.sync.tick(now)

        // if (!this.lastInterval || this.lastInterval.start !== this.sync.currentTypes['beats'].start) {
        //     this.lastInterval = this.sync.currentTypes['beats']
        // }

        // if (!this.lastIntervalBars || this.lastIntervalBars.start !== this.sync.currentTypes['bars'].start) {
        //     this.lastIntervalBars = this.sync.currentTypes['bars']
        // }

        // if (!this.lastIntervals['tatums'] || this.lastIntervals['tatums'].start !== this.sync.currentTypes['tatums'].start) {
        //     let co = this.colors.filter(color => color !== this.currentColors['tatums'])
        //     this.currentColors['tatums'] = co[Math.floor(Math.random() * (this.colors.length - 2))];
        //     this.lastIntervals['tatums'] = this.sync.currentTypes['tatums']

        //     // let cfo = this.colors.filter(color => color !== this.stars[this.changingStarIndex].color)
        //     // this.stars[this.changingStarIndex].color = cfo[Math.floor(Math.random() * (this.colors.length - 2))];
        //     // this.changingStarIndex = (this.changingStarIndex + 1) % this.stars.length

        //     // const a = interpolateBasis([.04 + this.sync.volume / 10, .04])(this.sync.currentTypes['bars'].progress)
        //     // this.stars.forEach(star => {
        //     //     let co = this.colors// .filter(color => color !== star.lastColor)
        //     //     star.lastColor = star.nextColor || co[Math.floor(Math.random() * (this.colors.length - 2))](a)
        //     //     star.nextColor = co[0](a);
        //     // })
        // }

        for (let i = 0; i < this.transitionEntities.length; i++) {
            const transitionEntity = this.transitionEntities[i];

            transitionEntity.tick();
        }

        for (let i = 0; i < this.entities.length; i++) {
            const entity = this.entities[i];

            if (entity.removed) {
                this.entities.splice(i, 1);

                if (entity.type === 'particle') {
                    this.particlePool.push(entity as Particle);
                }
            }

            entity.tick();
        }

        camera.tick();

        this.render();

        // this.g.fillStyle = this.currentColor
        // // this.g.fillRect(100, 100, 100, 100)
        // this.g.fillStyle = this.currentColor

        // // const beat = this.sync.currentTypes['beats']?.progress ?? 0
        // const bar = interpolateBasis([0, this.sync.volume * 10, 0])(this.sync.currentTypes['bars'].progress)
        // const beat = interpolateBasis([0, this.sync.volume * 300, 0])(this.sync.currentTypes['beats'].progress)

        // const a = interpolateBasis([.4, .3])(this.sync.currentTypes['bars'].progress)
        // // this.stars.forEach(star => {
        // //     let co = this.colors// .filter(color => color !== star.lastColor)
        // //     star.lastColor = star.nextColor || co[Math.floor(Math.random() * (this.colors.length - 2))](a)
        // //     star.nextColor = co[0](a);
        // // })

        // this.g.strokeStyle = 'black'
        // this.g.lineWidth = 1
        // sin(this.g, now / 50, 600 / 2, this.sync.volume * 50, 50)
        // this.g.stroke()

        // this.stars.forEach((star, i) => {
        //     let index = i % 12
        //     this.g.fillStyle = star.color
        //     this.g.strokeStyle = star.color

        //     star.speed = this.sync.volume
        //     // this.g.fillRect(star.x - (star.size + 10 * beat) / 2, star.y - (star.size + 10 * beat) / 2, star.size + 10 * beat, star.size + 10 * beat);

        //     // console.log(this.sync.currentTypes['segments']?.pitches[i])
        //     // star.size = this.sync.currentTypes['segments']?.pitch[i] * 50 ?? 0

        //     // star.color = `rgba(255, 255, 255, ${interpolateBasis([.02, .03])(this.sync.currentTypes['segments']?.timbre[index])})`

        //     star.color = interpolateRgb(star.lastColor, star.nextColor)(this.sync.currentTypes['bars'].progress)
        //     this.g.beginPath()
        //     //this.sync.currentTypes['segments']?.pitches[index] * 50
        //     // star.size > 50 ? interpolateBasis([star.size, star.size + this.sync.currentTypes['segments']?.pitches[index] *  (star.size / 4), star.size])(beat) : star.size
        //     this.g.arc(star.x, star.y, interpolateBasis([star.size, star.size + ((star.size / 12) * this.sync.volume), star.size])(this.sync.currentTypes['beats'].progress), 0, Math.PI * 2)

        //     // this.g.arc(star.x, star.y, this.sync.volume * star.size + beat / 10, 0, Math.PI * 2)
        //     this.g.fill()

        //     star.x += Math.cos(star.angle) * star.speed
        //     star.y += Math.sin(star.angle) * star.speed

        //     // stars can't hit each other
        //     // this.stars.forEach(otherStar => {
        //     //     // oval collision using center and radius adding half the size of the star
        //     //     if (star.x + star.size / 2 > otherStar.x - otherStar.size / 2 &&
        //     //         star.x - star.size / 2 < otherStar.x + otherStar.size / 2 &&
        //     //         star.y + star.size / 2 > otherStar.y - otherStar.size / 2 &&
        //     //         star.y - star.size / 2 < otherStar.y + otherStar.size / 2) {

        //     //         star.speed = Math.random() * (0.5) + 0.05
        //     //     } else {
        //     //         star.speed = 5
        //     //     }
        //     // })

        //     if (star.x > window.innerWidth || star.x < 0) {
        //         star.angle = Math.PI - star.angle
        //     }

        //     if (star.y > window.innerHeight || star.y < 0) {
        //         star.angle = -star.angle
        //     }
        // })

        // this.g.fillStyle = this.currentColorBars
        // this.g.fillRect(100, 300, 100, 100)

        // this.g.fillStyle = this.currentColors['tatums']
        // this.g.fillRect(100, 500, 100, 100)

        // this.sync.currentTypes['segments'].pitches.forEach((pitch, j) => {
        //     this.g.fillStyle = '#e0e0e0' // this.colors[j]
        //     this.g.fillRect(100 + j * 10, 300 - pitch * 100, 10, pitch * 100)
        // })

        // const beat = this.sync.currentTypes['tatums'].progress
        // this.g.strokeStyle = 'black'

        // this.g.beginPath()
        // this.g.lineWidth = beat
        // this.g.arc(150, 550, this.sync.volume * 100 / 5 + beat / 10, 0, Math.PI * 2)
        // this.g.stroke()

        // this.g.beginPath()
        // this.g.lineWidth = 1
        // this.g.arc(150, 550, (20 * (beat / 10)) + 20, 0, Math.PI * 2)
        // this.g.stroke()

        // this.g.beginPath()
        // this.g.lineWidth = beat
        // this.g.arc(150, 550, (20 * (beat / 15)) + 30, 0, Math.PI * 2)
        // this.g.stroke()
    }

    render() {
        this.g.clearRect(0, 0, this.width, this.height);

        for (let transitionEntity of this.transitionEntities) {
            transitionEntity.render(this.g);
        }

        for (let entity of this.entities) {
            entity.render(this.g);
        }
    }
}

class Entity {
    x: number;
    y: number;
    width: number;
    height: number;

    type = "entity";

    removed: boolean = false;

    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    tick() { }

    render(g) { }
}

function easeInExpo(x: number): number {
    return x === 0 ? 0 : Math.pow(2, 10 * x - 10);
}

class Transition extends Entity {
    color: string;
    type = "transition";

    life = 0;
    lifeTime = 60;

    ya = 0;

    constructor(x, y, color = "red") {
        super(x, y);

        this.color = color;
        this.height = 0;
        this.width = window.innerWidth;
    }

    tick() {
        if (this.life < this.lifeTime) {
            this.life++;

            const y = this.life / this.lifeTime

            this.height = window.innerHeight * easeInExpo(y);
        } else {
            if (!this.removed) {
                this.removed = true;

                document.body.style.background = this.color;

                camera.shake(.6);
            }
        }

        this.ya += .7;
    }

    render(g) {
        g.fillStyle = this.color;
        g.fillRect(0, 0, this.width, this.height);
    }
}

class Particle extends Entity {
    type = "particle";
    angle: number;
    speed: number;
    life = 0;
    lifeTime = 60;
    timeOffset = 0;
    color = { r: 0, g: 0, b: 0 };

    ya = 0;

    rotateSpeed = 0.1;


    constructor(x, y, angle, speed, lifeTime, color) {
        super(x, y);

        this.angle = angle;
        this.speed = speed;
        this.lifeTime = lifeTime;
        this.color = color;

        this.ya = -randomRange(5, 15);

        this.rotateSpeed = Math.random() > 0.5 ? -0.05 : 0.05;
        this.timeOffset = Math.random() * 50;
    }

    tick() {
        this.x += Math.cos(this.angle) * this.speed;
        this.y += this.ya;

        this.life++;

        if (this.life >= this.lifeTime) {
            this.removed = true;
        }

        this.ya += .5;
    }

    render(g) {
        const drawAngle = this.angle + (Math.sin((this.life + this.timeOffset) * this.rotateSpeed)) * (Math.PI / 6);
        const startX = this.x - Math.cos(drawAngle) * 30;
        const startY = this.y - Math.sin(drawAngle) * 30;

        const endX = this.x + Math.cos(drawAngle) * 30;
        const endY = this.y + Math.sin(drawAngle) * 30;

        g.strokeStyle = `rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, ${1 - (this.life / this.lifeTime)}`;

        g.lineWidth = 30;
        g.beginPath();
        g.moveTo(startX, startY);
        g.lineTo(endX, endY);
        g.stroke();
    }
}

class Firework extends Particle {
    type = "particle";
    angle: number;
    speed: number;
    life = 0;
    lifeTime = 60;
    timeOffset = 0;
    color = { r: 0, g: 0, b: 0 };

    ya = 0;

    rotateSpeed = 0.1;

    constructor(x, y, angle, speed, lifeTime, color) {
        super(x, y, angle, speed, lifeTime, color);

        this.timeOffset = Math.random() * 50;
    }

    tick() {
        this.x += Math.cos(this.angle) * this.speed;
        this.y += Math.sin(this.angle) * this.speed;

        this.life++;

        if (this.life >= this.lifeTime) {
            this.removed = true;
        }

        //this.ya += .01;
    }

    render(g) {
        const startX = this.x;// - Math.cos(this.angle) * 3;
        const startY = this.y;// - Math.sin(this.angle) * 3;

        const endX = this.x + Math.cos(this.angle) * this.speed;
        const endY = this.y + Math.sin(this.angle) * this.speed;

        g.strokeStyle = `rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, ${1 - (this.life / this.lifeTime)}`;

        g.lineWidth = 1;
        g.beginPath();
        g.moveTo(startX, startY);
        g.lineTo(endX, endY);
        g.stroke();
    }
}