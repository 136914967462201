import React from 'react'
import { SpotifyWithPlayback } from './Player'
import useSpotifyWebPlayback from './useSpotifyWebPlayback'
import useOAuth, { AuthProvider } from '../SpotifyStandalone/useOAuth'

function Authorize() {
  return (
    <a href={`https://accounts.spotify.com/authorize?client_id=60cca0ef8c7a466f8f6ec0e8d774a538&response_type=code&redirect_uri=${window.location.href}&scope=user-modify-playback-state%20user-read-playback-state%20streaming%20user-read-email%20user-read-private`}>Authorize</a>
  )
}

const url = process.env.SPOTIFY_GET_TOKEN

export default function TokenLoaderContainer() {
  return (
    <AuthProvider url={url}>
      <TokenLoader />
    </AuthProvider>
  )
}

export function TokenLoader() {
  const { accessToken, loading } = useOAuth()

  if (!loading && accessToken) {
    return <OuterPlayer token={accessToken} />
  }

  if (!loading && !accessToken) {
    return <Authorize />
  }
}

function OuterPlayer({ token }) {
  const { player, currentState } = useSpotifyWebPlayback(token)

  const spotifyWebApi = {
    play: () => {
      return player.resume()
    },
    pause: () => {
      return player.pause()
    },
    setVolume: (val) => {
      return player.setVolume(val)
    },
    skipToNext: async () => {
      return await player.nextTrack()
    },
    seek: (val) => {
      return player.seek(val)
    }
  }

  if (currentState) {
    return (
      <SpotifyWithPlayback
        spotifyWebApi={spotifyWebApi}
        currentState={currentState}
      />
    )
  }

  return <h1>Loading...</h1>
}