import { useState, useEffect } from 'react';

export function useQueryParam(name) {
    const [param, setParam] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setParam(urlParams.get(name));
    }, [name]);

    return param;
}