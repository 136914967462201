import { useEffect, useState } from "react";

export const getItem = key => {
    return document.cookie.split("; ").reduce((total, currentCookie) => {
        const item = currentCookie.split("=");
        const storedKey = item[0];
        const storedValue = item[1];
        
        if(storedValue === 'undefined') {
            const now = new Date();
            document.cookie = `${key}=${''}; expires=${now.toUTCString()}; path=/`;
        }

        return key === storedKey ? decodeURIComponent(storedValue) : total;
    }, "");
}

export const setItem = (key, value, numberOfSeconds) => {
    const now = new Date();

    // set the time to be now + numberOfDays

    if(numberOfSeconds !== undefined) {
        now.setTime(now.getTime() + numberOfSeconds * 1000);
    } else { 
        now.setTime(2147483647 * 1000)
    }

    document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
};

/**
 *
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
const useCookie = (key, defaultValue) => {
    const getCookie = () => getItem(key) || defaultValue;
    const newCookie = getCookie()
    const [cookie, setCookie] = useState(newCookie);

    const updateCookie = (value, numberOfSeconds) => {
        if(value) {
            setCookie(value);
            setItem(key, value, numberOfSeconds);
        }
    };

    useEffect(() => {
        setCookie(newCookie)
    }, [newCookie])

    return [cookie, updateCookie];
};

export default useCookie;
