import { scaleLog } from 'd3-scale'
import { min } from 'd3-array'

function easeOutQuart(t) { return 1 - (--t) * t * t * t }

export default function interpolate(a, b) {
    return function (t) {
        return a * (1 - t) + b * t
    }
}

export class Sync {
    initialTime = window.performance.now()
    trackProgress: number = 0
    currentIntervalIndex
    currentSegment

    currentBars
    initialProgress
    analysis

    types = ['beats', 'bars', 'tatums', 'sections', 'segments']
    currentTypes = {

    }

    volume = 0

    queues = {
        volume: [],
        beat: []
    }

    setAnalysis(analysis) {
        this.analysis = analysis
        //this.currentIntervalIndex = 0
        // this.currentSegment = this.analysis.segments[this.currentIntervalIndex]
    }

    setState() {
        this.types.forEach(type => {
            const newIndex = this.determineInterval(type)
            this.currentTypes[type] = this.analysis[type][newIndex]

            const { start, duration } = this.currentTypes[type]
            const elapsed = (this.trackProgress / 1000) - start
            this.currentTypes[type].elapsed = elapsed
            this.currentTypes[type].progress = easeOutQuart(elapsed / duration)
            this.currentTypes[type].index = newIndex
        })

        // let typebars = 'bars'
        // this.currentIntervalIndex = this.determineInterval(typebars)
        // this.currentBars = this.analysis[typebars][this.currentIntervalIndex]
    }

    determineInterval = (type) => {
        const analysis = this.analysis[type]
        const progress = this.trackProgress / 1000

        for (let i = 0; i < analysis.length; i++) {
            if (i === (analysis.length - 1)) return i
            if (analysis[i].start < progress && progress < analysis[i + 1].start) return i
        }
    }

    setInitialProgress(time) {
        this.initialProgress = time
    }

    getVolume() {
        const {
            loudness_max,
            loudness_start,
            loudness_max_time,
            duration,
            elapsed,
            start,
            index
        } = this.currentTypes.segments

        if (!this.analysis.segments[index + 1]) return 0

        const next = this.analysis.segments[index + 1].loudness_start
        const current = start + elapsed

        if (elapsed < loudness_max_time) {
            const progress = Math.min(1, elapsed / loudness_max_time)
            return interpolate(loudness_start, loudness_max)(progress)
        } else {
            const _start = start + loudness_max_time
            const _elapsed = current - _start
            const _duration = duration - loudness_max_time
            const progress = Math.min(1, _elapsed / _duration)
            return interpolate(loudness_max, next)(progress)
        }
    }

    tick(now) {
        if (this.analysis) {
            this.trackProgress = (now - this.initialTime) + this.initialProgress

            //console.log(this.trackProgress)
            this.setState()

            /** Get current volume. */
            const volume = this.getVolume()
            const queues = this.queues

            /** Add volume value to the beginning of the volume queue. */
            queues.volume.unshift(volume)

            /** If the queue is larger than 400 values, remove the last value. */
            if (queues.volume.length > 400) {
                queues.volume.pop()
            }

            /** Add volume value to the beginning of the beat queue. */
            queues.beat.unshift(volume)

            /** If the queue is larger than our defined smoothing value, remove the last value. */
            if (queues.beat.length > 100) {
                queues.beat.pop()
            }

            const average = (arr) => {
                return arr.reduce((a, b) => (a + b)) / arr.length
            }

            /** Scale volume (dB) to a linear range using the minimum and average values of the volume queue. */
            const sizeScale = scaleLog()
                .domain([min(queues.volume), average(queues.volume)])
                .range([0, 1])

            /** Average the beat queue, then pass it to our size scale. */
            const beat = average(queues.beat)
            this.volume = sizeScale(beat)
        }
    }
}
