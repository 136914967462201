import React from 'react';
import { useEffect, useState, useRef } from 'react';
import Timer from '../Timer';
import { Container, Icon } from 'semantic-ui-react';
import { useLocalStorage } from 'beautiful-react-hooks';
import { Fireworks } from '../Fireworks';
import { useSpotify } from './useSpotifyAuthentication';
import useDebounce from '../../hooks/useDebounce';
import Clock from '../Clock/Clock';

export interface ISpotifyProps {
}

var audio = new Audio('can-sound.mp3');
audio.volume = .5;

function promiseTimer(time) {
    return new Promise((resolve) => {
        setTimeout(resolve, time);
    });
}

const Wait = {
    milliseconds: (delay = 0) => {
        return promiseTimer(delay)
    },
    seconds: (delay = 0) => {
        return Wait.milliseconds(delay * 1000)
    },
    minutes: (delay = 0) => {
        return Wait.seconds(delay * 60)
    },
    hours: (delay = 0) => {
        return Wait.minutes(delay * 60)
    }
}


function Playing({ onClick }) {
    return <Icon name='pause circle' size="huge" className="icon-button" onClick={onClick} />
}

function Paused({ onClick }) {
    return <Icon name='play circle' size="huge" className="icon-button" onClick={onClick} />
}

function Player({ pause, play, playing, onVolumeChange, volume }) {
    return (
        <div className="position-absolute absolute-center show-on-mouse">
            <div className="control-button">
                {(playing ?
                    <Playing onClick={pause} /> :
                    <Paused onClick={play} />)}
            </div>
            <div style={{ marginTop: '1rem', width: '180px' }}>
                <input title="volume" id="volume" type="range" min="0" max="100" onChange={onVolumeChange} value={volume} style={{ width: '100%' }} />
            </div>
        </div>
    )
}

function useDebouncedValue(onChange) {
    const [value, setValue] = useState(null)
    const debouncedValue = useDebounce(value, 300)

    useEffect(() => {
        if (debouncedValue !== null) {
            onChange && onChange(debouncedValue)
        }
    }, [debouncedValue])

    return [value, setValue]
}

export function SpotifyStandalone(props: ISpotifyProps) {
    const { authorized, spotifyWebApi } = useSpotify()
    const [playing, setPlaying] = useState(false);
    const [currentSong, setCurrentSong] = useState(null);

    const [volumeValue, setVolumeValue] = useDebouncedValue((volume) => {
        if (volume !== null) {
            spotifyWebApi.setVolume(volume).then(() => { })
        }
    })

    function onVolumeChange(e) {
        setVolumeValue(e.target.value)
    }

    const gameRef = useRef<any>();


    useEffect(() => {
        if (spotifyWebApi && !currentSong) {
            spotifyWebApi.getMyCurrentPlaybackState().then(async track => {
                setPlaying(track.is_playing)
                setCurrentSong({ body: track });

                if (track.item.album) {
                    const color = await getAverageColor(track.item.album.images[0].url);
                    document.body.style.background = color;
                }

                setVolumeValue(track.device.volume_percent)


                // if (gameRef.current) {
                //     // console.log(determineInterval('segments', track.item.progress_ms))
                //     spotifyWebApi.getAudioAnalysisForTrack(track.item.id).then(analysis => {
                //         console.log(gameRef.current)
                //         gameRef.current?.setAnalysis(analysis)
                //         gameRef.current?.setSyncInitialProgress(track.progress_ms)

                //     })
                // }

                // spotifyWebApi.getAudioFeaturesForTrack(track.item.id).then(features => {
                //     console.log(features)
                // })
            }).catch(console.error)
        }
    }, [spotifyWebApi])

    function doFireworks(amount) {
        // Emit fireworks
        if (gameRef.current) {
            for (var i = 0; i < amount; i++) {
                const x = window.innerWidth / (amount + 1) * (i + 1);//randomRange(0, window.innerWidth - 0);
                //const y = randomRange(0, window.innerHeight - 0);
                const y = randomRange(40, 100)
                // randomRange(-30, 100)
                setTimeout(() => {
                    gameRef.current.emit(x, y, 25);

                }, Math.floor(Math.random() * 1000));
            }
        }
    }

    async function handleTimerFinish(minute) {
        async function nextSong() {
            audio.volume = ((currentSong?.volume || 100) / 100) - 0.5;
            audio.play();

            doFireworks(minute)

            await next();

            setPlaying(true);
        }

        setPlaying(false);
        await nextSong();
    }

    async function play() {
        try {
            await spotifyWebApi?.play()
        } catch (err) {
            console.error(err)
        } finally {
            setPlaying(true)
        }
    }

    async function pause() {
        try {
            await spotifyWebApi?.pause()
            setPlaying(false)
        } catch (err) {
            console.error(err)
        }
    }

    async function next() {
        if (spotifyWebApi) {
            try {
                await spotifyWebApi.setVolume(0);
            } catch (err) {
                console.error(err)
                await spotifyWebApi.skipToNext()
                await spotifyWebApi.pause()

                await Wait.milliseconds(500)

                try {
                    const track = await spotifyWebApi.getMyCurrentPlayingTrack()

                    const roundLength = 65000;
                    if (track.item.duration_ms > roundLength) {
                        const startTime = Math.floor(Math.random() * (track.item.duration_ms - roundLength));
                        await spotifyWebApi.seek(startTime)
                    }

                    const color = await getAverageColor(track.item.album.images[0].url);
                    gameRef.current.transition(color);
                    setCurrentSong({ body: track });

                    await Wait.milliseconds(500)
                } catch (err) {
                    console.error(err)
                }

                await spotifyWebApi.play()

                return
            }

            try {
                await spotifyWebApi.skipToNext()

                await Wait.milliseconds(500)
            } catch (err) {
                console.error(err)
            }

            try {
                const track = await spotifyWebApi.getMyCurrentPlayingTrack()

                const roundLength = 65000;
                if (track.item.duration_ms > roundLength) {
                    const startTime = Math.floor(Math.random() * (track.item.duration_ms - roundLength));
                    await spotifyWebApi.seek(startTime)
                }

                const color = await getAverageColor(track.item.album.images[0].url);
                gameRef.current.transition(color);
                setCurrentSong({ body: track });

                await Wait.milliseconds(500)
            } catch (err) {
                console.error(err)
            }

            try {
                await spotifyWebApi.setVolume(volumeValue);
            } catch (err) {
                console.error(err)
            }
        }
    }

    async function getAverageColor(url): Promise<string> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.crossOrigin = 'Anonymous';
            image.src = url;

            image.addEventListener('load', () => {
                var rgb = getAverageRGB(image);
                resolve('rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')');
            });
        });
    }

    useEffect(() => {
        if (spotifyWebApi && currentSong?.body?.item) {
            spotifyWebApi.getAudioAnalysisForTrack(currentSong.body.item.id).then(analysis => {
                gameRef.current?.setAnalysis(analysis)
                gameRef.current?.setSyncInitialProgress(currentSong.body.progress_ms)

            })
        }
    }, [spotifyWebApi, currentSong])

    function Authorize(props) {
        return (
            <a href={`https://accounts.spotify.com/authorize?client_id=60cca0ef8c7a466f8f6ec0e8d774a538&response_type=code&redirect_uri=${window.location.href}&scope=user-modify-playback-state%20user-read-playback-state`}>Authorize</a>
        )
    }

    return (
        <>
            <Fireworks ref={gameRef} />
            <Container className="p-2 center d-flex flex-column absolute-center player-container">
                <div className="p-2" style={{ borderRadius: "1rem", marginTop: "1rem", background: "rgba(0,0,0,52%)", boxShadow: "1px 1px 10px #000000a6", position: 'relative' }}>
                    {authorized && currentSong?.body?.item && (
                        <>
                            <Clock running={playing} onTimerFinish={handleTimerFinish} />

                            <div style={{ paddingBottom: "1rem", margin: "0 auto", fontSize: "40px", maxWidth: "70%", lineHeight: "100%" }}>
                                <div style={{ marginBottom: "1rem" }}>{currentSong.body.item.name}</div>
                                <div style={{ color: "#ffffffc9" }}>
                                    {currentSong.body.item.artists.map(artist => artist.name).join(", ")}
                                </div>
                            </div>

                            <div style={{ width: "30%", margin: "0 auto", fontSize: "40px" }} className="position-relative album-controls">
                                <img alt="Album cover" src={currentSong.body.item.album.images[0].url} style={{ width: "100%" }} />
                                {volumeValue !== null && <Player playing={playing} play={play} pause={pause} volume={volumeValue} onVolumeChange={onVolumeChange} />}
                            </div>
                        </>
                    )}

                    {authorized && !currentSong?.body && (
                        <div style={{ margin: "0 auto" }}>Start a song on Spotify and refresh this page</div>
                    )}

                    {!authorized && <Authorize />}
                </div>
            </Container>
        </>
    );
}

function randomRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function getAverageRGB(imgEl) {

    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0;

    if (!context) {
        return defaultRGB;
    }

    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
        data = context.getImageData(0, 0, width, height);
    } catch (e) {
        console.log('diff domain');
        /* security error, img on diff domain */
        return defaultRGB;
    }

    length = data.data.length;

    while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    return rgb;

}
