// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JnQCWmliqmAa9_coQP9u{color:red}.ibW2JlzbAkkACrFTTqc_{margin-top:1rem;width:180px}.ibW2JlzbAkkACrFTTqc_ input{width:100%}.LSW6a1fZmfgkZ7nnUvoP{border-radius:1rem;margin-top:1rem;background:#00000085;box-shadow:1px 1px 10px #000000a6;position:relative}.JzYrz_SJyc8vzv2mQkhU{padding-bottom:1rem;margin:0 auto;font-size:40px;max-width:70%;line-height:100%}.A6svtHl9ZfybSVYhUxSu{margin-bottom:1rem}.F9Q_GgUXNNrhH57yPFAD{color:#ffffffc9}.dxqH2y_FchBuEQ1ddMH2{width:30%;margin:0 auto;font-size:40px}.dxqH2y_FchBuEQ1ddMH2 img{width:100%}.HLbgVNMGK2dGDlCBsSZf{margin:0 auto}
`, "",{"version":3,"sources":["webpack://./src/components/SpotifyWebPlayback/player.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAU,CACX,sBAGC,eAAgB,CAChB,WAAY,CAFd,4BAKI,UAAW,CACZ,sBAID,kBAAmB,CACnB,eAAgB,CAChB,oBAAqB,CACrB,iCAAkC,CAClC,iBAAkB,CACnB,sBAGC,mBAAoB,CACpB,aAAc,CACd,cAAe,CACf,aAAc,CACd,gBAAiB,CAClB,sBAGC,kBAAmB,CACpB,sBAGC,eAAgB,CACjB,sBAGC,SAAU,CACV,aAAc,CACd,cAAe,CAHjB,0BAMI,UAAW,CACZ,sBAID,aAAc","sourcesContent":[".test {\n  color: red;\n}\n\n.volumeInput {\n  margin-top: 1rem; \n  width: 180px;\n\n  input {\n    width: 100%;\n  }\n}\n\n.player {\n  border-radius: 1rem;\n  margin-top: 1rem;\n  background: #00000085;\n  box-shadow: 1px 1px 10px #000000a6;\n  position: relative;\n}\n\n.display {\n  padding-bottom: 1rem;\n  margin: 0 auto;\n  font-size: 40px;\n  max-width: 70%;\n  line-height: 100%;\n}\n\n.songName {\n  margin-bottom: 1rem;\n}\n\n.songArtists {\n  color: #ffffffc9;\n}\n\n.albumControls {\n  width: 30%;\n  margin: 0 auto;\n  font-size: 40px;\n\n  img {\n    width: 100%;\n  }\n}\n\n.authorize {\n  margin: 0 auto;\n}\n\n//borderRadius: \"1rem\", marginTop: \"1rem\", background: \"rgba(0,0,0,52%)\", \n// boxShadow: \"1px 1px 10px #000000a6\", position: 'relative'"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"test": `JnQCWmliqmAa9_coQP9u`,
	"volumeInput": `ibW2JlzbAkkACrFTTqc_`,
	"player": `LSW6a1fZmfgkZ7nnUvoP`,
	"display": `JzYrz_SJyc8vzv2mQkhU`,
	"songName": `A6svtHl9ZfybSVYhUxSu`,
	"songArtists": `F9Q_GgUXNNrhH57yPFAD`,
	"albumControls": `dxqH2y_FchBuEQ1ddMH2`,
	"authorize": `HLbgVNMGK2dGDlCBsSZf`
};
export default ___CSS_LOADER_EXPORT___;
