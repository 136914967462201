import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Icon } from 'semantic-ui-react';
import { PlayerWrapper } from '../PlayerWrapper';

export interface ISpotifyProps {
}

export function SpotifyWithWrapper(props: ISpotifyProps) {
    const [authorized, setAuthorized] = useState(false);
    const [authenticateLink, setAuthenticateLink] = useState('');
    const [playing, setPlaying] = useState(false);
    const [currentSong, setCurrentSong] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState("#3e3e3e");

    // const url = '/api';
    const url = 'http://192.168.1.111:3030/api'
    
    useEffect(() => {
        axios.get(`${url}/login`, { withCredentials: true }).then(async (res) => {
            const data = res.data;
            if (data.authenticated) {
                if (data.playing.body && data.playing.body.is_playing) {
                    setPlaying(true);
                    setCurrentSong(data.playing);

                    const color = await getAverageColor(data.playing.body.item.album.images[0].url);
                    setBackgroundColor(color);
                } else {
                    setPlaying(false);
                }

                setAuthorized(true);
            } else {
                setAuthenticateLink(data);

                var urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has("code")) {
                    // pass the code into the backend to authenticate
                    axios.get(`${url}/code?code=` + urlParams.get("code"), { withCredentials: true }).then(data => {
                        if (data.data === true) {
                            setAuthorized(true);
                            window.history.replaceState({}, document.title, "/");
                        }
                    });
                }
            }
        });
    }, []);

    useEffect(() => {
        if (authorized && !currentSong) {
            axios.get(`${url}/current`, { withCredentials: true }).then(async data => {
                if (data.data.body.item) {
                    setCurrentSong(data.data);

                    const color = await getAverageColor(data.data.body.item.album.images[0].url);
                    setBackgroundColor(color);
                }
            }).catch(console.error)
        }
    }, [authorized]);

    function play() {
        axios.get(`${url}/play`, { withCredentials: true }).then((data) => {
            setPlaying(true);
        });
    }

    function pause() {
        axios.get(`${url}/pause`, { withCredentials: true }).then((data) => {
            setPlaying(false);
        })
    }

    async function next() {
        const data = await axios.get(`${url}/next?volume=${currentSong?.volume || 100}`, { withCredentials: true });
        const color = await getAverageColor(data.data.body.item.album.images[0].url);

        setBackgroundColor(color);
        setCurrentSong(data.data);
    }

    async function getAverageColor(url): Promise<string> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.crossOrigin = 'Anonymous';
            image.src = url;

            image.addEventListener('load', () => {
                var rgb = getAverageRGB(image);
                resolve('rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')');
            });
        });
    }

    useEffect(() => {
        if (backgroundColor) {
            document.body.style.background = backgroundColor;
        }
    }, [backgroundColor]);

    function prev() {
        axios.get(`${url}/prev`, { withCredentials: true });
    }

    function Player(props) {
        return (
            <div className="position-absolute absolute-center control-button">
                {(props.playing ?
                    <Playing /> :
                    <Paused />)}
            </div>
        )
    }

    function Authorize(props) {
        return (
            <a href={props.link}>Authorize</a>
        )
    }

    function Playing(props) {
        return <Icon name='pause circle' size="huge" className="icon-button" onClick={pause} />
    }

    function Paused(props) {
        return <Icon name='play circle' size="huge" className="icon-button" onClick={play} />
    }

    return (
        <PlayerWrapper
            onNext={next}
            playing={playing}
            setPlaying={setPlaying}
            backgroundColor={backgroundColor}
            showTime={!!authorized}
        >
            {authorized && currentSong && currentSong.body.item &&
                <>
                    <div style={{ paddingBottom: "1rem", margin: "0 auto", fontSize: "40px", maxWidth: "70%", lineHeight: "100%" }}>
                        <div style={{ marginBottom: "1rem" }}>{currentSong.body.item.name}</div>
                        <div style={{ color: "#ffffffc9" }}>
                            {currentSong.body.item.artists.map(artist => artist.name).join(", ")}
                        </div>
                    </div>

                    <div style={{ width: "30%", margin: "0 auto", fontSize: "40px" }} className="position-relative album-controls">
                        <img src={currentSong.body.item.album.images[0].url} style={{ width: "100%" }} />
                        <Player playing={playing} />
                    </div>
                </>
            }

            {authorized && !currentSong && (
                <>
                    <div style={{ margin: "0 auto" }}>Start a song on Spotify and refresh this page</div>
                </>
            )}

            {!authorized && <Authorize link={authenticateLink} />}
        </PlayerWrapper>
    );
}

function getAverageRGB(imgEl) {

    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0;

    if (!context) {
        return defaultRGB;
    }

    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
        data = context.getImageData(0, 0, width, height);
    } catch (e) {
        console.log('diff domain');
        /* security error, img on diff domain */
        return defaultRGB;
    }

    length = data.data.length;

    while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    return rgb;

}
