export class Camera {
    intensity = 0;

    tick() {
        if (this.intensity > 0) {
            this.intensity -= .01;
        } else if (this.intensity < 0) {
            this.intensity = 0;
        }

        const shake = Math.pow(this.intensity, 3);
        const xp = 10 * shake * (Math.random() * 3 - 1);
        const yp = 10 * shake * (Math.random() * 3 - 1);



        document.body.style.transform = `translate(${xp}px, ${yp}px)`;
    }

    shake(intensity) {
        this.intensity = intensity;
    }
}
