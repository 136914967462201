import { useLocalStorage } from 'beautiful-react-hooks';
import React, { useState, useEffect, useCallback, useRef } from 'react';


const countdownTime = 60
export interface TimerProps {
    counting: boolean;
    renderType: 'seconds' | 'minutes';
    onFinished?: () => Promise<void>;
}

function useTimer(initialValue, onTick, tickRate = 1000) {
    const [time, setTime] = useState(initialValue);
    const [counting, setCounting] = useState(false);

    const [startTime, setStartTime] = useState(null)

    useEffect(() => {
        if (initialValue !== time) {
            setTime(initialValue);
        }
    }, [initialValue]);

    const start = () => {
        setStartTime(Date.now() - ((60 - time) * 1000))
        setCounting(true);
    }

    const stop = () => {
        setStartTime(null)
        setCounting(false);
    }

    const tick = useCallback(() => {
        const now = Date.now()
        const diff = Math.ceil((now - startTime) / 1000)

        if (typeof onTick === 'function') {
            // onTick(time);
            onTick(60 - diff)
        }

        // setTime(time - 1);
        setTime(60 - diff)
    }, [time, startTime]);

    useEffect(() => {
        if (counting) {
            const timer = setInterval(tick, tickRate);
            return () => clearInterval(timer);
        }
    }, [counting, tickRate, tick]);

    return {
        time,
        start,
        stop,
    }
}

export function Time({ renderType, time }) {
    if (renderType === 'minutes') {
        const minutes = Math.floor(time / 60);
        const seconds = ('0' + time % 60).substr(-2, 2);

        return <>{minutes}:{seconds}</>;
    }

    if (renderType === 'seconds') {
        const seconds = ('0' + time).substr(-2, 2);

        return <>{seconds}</>;
    }
}

function useInterval(callback, delay) {
    const savedCallback = useRef(() => { })

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        function tick() {
            savedCallback.current()
        }

        if (delay !== null) {
            const interval = setInterval(tick, delay)
            return () => clearInterval(interval)
        }
    }, [delay])
}

export default function Timer({ counting, renderType, onFinished }: TimerProps) {
    const [currentTime, setCurrentTime] = useLocalStorage<number>("currentTime", countdownTime);
    const [playing, setPlaying] = useState(counting)
    const [showing, setShowing] = useState(true)

    useInterval(() => {
        if (currentTime <= 0) {
            setPlaying(false)
            return onFinished().then(() => {
                setPlaying(true)
                setCurrentTime(countdownTime)
            })
        }

        setCurrentTime(currentTime - 1)
    }, playing ? 1000 : null)

    useEffect(() => {
        setPlaying(counting)
    }, [counting]);

    const restartTimer = () => {
        setCurrentTime(countdownTime)
    }

    return (
        <span data-testid="timer" className="time" onDoubleClick={restartTimer}>
            {showing ?
                <Time renderType={renderType} time={currentTime} /> : "--"
            }
        </span>
    );
}